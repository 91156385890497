import React from 'react'
import { graphql } from 'gatsby'

import AuthGuard from '@babelcoder/gatsby-theme-base/src/components/AuthGuard'
import Register from '../../components/courses/online/Register'
import useCoursesPath from '../../hooks/useCoursesPath'

export default function RegisterTemplate({ data: { course } }) {
  const { getCoursePath } = useCoursesPath()

  return (
    <AuthGuard emailMustVerified={true} redirectTo={getCoursePath(course.slug)}>
      <Register {...course}></Register>
    </AuthGuard>
  )
}

export const pageQuery = graphql`
  query OnlineRegisterQuery($slug: String!) {
    course: coursesYaml(slug: { eq: $slug }) {
      slug
      title
      price
      promotion
    }
  }
`
